<template>
  <app-card-wizard
    title="Mevcut Başvuru"
    :showProgress="false"
    v-if="!isMounting"
  >
    <template #description>
      <p>
        Yapmış olduğunuz başvuru sonrasında size e-posta ile gönderilmiş olan
        başvuru kodunuzu girin.
      </p>
    </template>
    <app-form-group
      id="1"
      name="code"
      label="Başvuru kodu"
      :validator="validator"
    >
      <input
        id="code1"
        name="code"
        class="form-control"
        v-model.trim="code"
        placeholder="Lütfen başvuru kodunuzu girin"
      />
    </app-form-group>
    <app-wizard-buttons
      v-bind:next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      next-button-text="Devam Et"
      @next-button-click="onSubmit"
      :prev-button-visible="true"
      prev-button-text="Ana Sayfaya Dön"
      @prev-button-click="goTo('/')"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
import { rules } from "../shared";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showPageError: false,
      hasCodeFromParams: false,
      isMounting: true,
      isLoading: false,
      code: null
    };
  },
  validations: {
    code: rules.required
  },
  methods: {
    hideLoading() {
      this.hidePageLoader();
      this.isLoading = false;
      this.isMounting = false;
    },
    loadApplication() {
      this.showPageLoader("Başvuru sorgulanıyor");
      this.isLoading = true;

      setTimeout(() => {
        this.loadApplicationData({ email: null, code: this.code })
          .then((response) => {
            if (response) {
              setTimeout(() => {
                this.findRouteToGo();
                this.hideLoading();
              }, 200);
            } else {
              throw new Error("");
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              title: "Geçersiz Başvuru",
              html:
                "Girmiş olduğunuz başvuru numarası ile kayıtlı devam eden bir başvuru bulunamadı. Lütfen başvuru kodunu kontrol edip tekrar deneyin.",
              confirmButtonText: "Tamam"
            });
            this.hideLoading();
          });
      }, 1000);
    },
    onSubmit() {
      this.validator.$touch();

      if (this.validator.$invalid) return;

      this.loadApplication();
    },
    ...mapActions(["loadApplicationData"])
  },
  computed: {
    validator() {
      return this.$v;
    }
  },
  beforeMount() {
    this.code = this.$route.params.applicationCode;

    if (this.code) {
      this.hasCodeFromParams = true;
      this.loadApplication();
    } else {
      this.isMounting = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
